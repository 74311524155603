
var scroll = window.requestAnimationFrame ||
    function(callback){ window.setTimeout(callback, 1000/60)};

var elementsToShow = document.querySelectorAll('img');

function loop() {

    elementsToShow.forEach(function (element) {
        if (isElementInViewport(element)) {
            setTimeout( function () {
            element.classList.add('is-visible');
            }, 500);
        } else {
            // element.classList.remove('is-visible');
        }
    });

    scroll(loop);
}

loop();

// Helper function from: http://stackoverflow.com/a/7557433/274826
function isElementInViewport(el) {
    // special bonus for those using jQuery
/*    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }*/
    var rect = el.getBoundingClientRect();
    return (
        (rect.top < 0
            && rect.bottom > 0)
        ||
        (rect.bottom > (window.innerHeight || document.documentElement.clientHeight) &&
            rect.top < (window.innerHeight || document.documentElement.clientHeight))
        ||
        (rect.top > 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
    );
}
