import MenuSpy from "menuspy";
import SmoothScroll from "smooth-scroll";

var elm = document.querySelector('#menu-content');

var ms = new MenuSpy(elm, {

  // menu selector
  menuItemSelector: '#menu-content a',

  // CSS class for active item
  activeClass: 'active',

  // amount of space between your menu and the next section to be activated.
  threshold: 15,

  // enable or disable browser's hash location change.
  enableLocationHash: true,

  // timeout to apply browser's hash location.
  hashTimeout: 600,

  // called every time a new menu item activates.
  callback: null,
});

var scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

//toggle mobile menu

function my_handler() {
  document.getElementById('mainmenu').classList.toggle('active');
}

document.getElementById('menu-toggler').onclick = my_handler;

/**
 * Close menu after clicking on link in mainmenu
 */
document.addEventListener('click', function (event) {
  
  // If the clicked element doesn't have the right selector, bail
  if (!event.target.matches('.mainmenu span')) return;

  my_handler();

}, false);
