
if(document.getElementById('btn-primary')) {
  initButton('btn-primary');
}

if(document.getElementById('btn-download')) {
    initButton('btn-download');
}

function initButton(id) {

  var el = document.getElementById(id);
  el.addEventListener('mouseenter', function (e) {

    var parentOffset = getOffset(el),
      relX = e.pageX - parentOffset.left,
      relY = e.pageY - parentOffset.top;
    el.querySelectorAll('span')[0].setAttribute("style", "top:" + relY + "px; left:" + relX + "px");
  });
  el.addEventListener('mouseout', function (e) {
    var parentOffset = getOffset(el),
      relX = e.pageX - parentOffset.left,
      relY = e.pageY - parentOffset.top;
    el.querySelectorAll('span')[0].setAttribute("style", "top:" + relY + "px; left:" + relX + "px");

  });
}

function getOffset(el) {
  var _x = 0;
  var _y = 0;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    _x += el.offsetLeft - el.scrollLeft;
    _y += el.offsetTop - el.scrollTop;
    el = el.offsetParent;
  }
  return {top: _y, left: _x};
};
