var mycookieValue = JSON.parse(getCookie("klaro"));

if (document.getElementById('map') && mycookieValue) {
    if (mycookieValue.mapbox == true) {
        setTimeout(function () {
            initMap();
            document.getElementById('map').classList.add('active');
        }, 1000)
    }
} else if (document.getElementById('map') && !mycookieValue) {
    document.getElementById('map').classList.remove('active');
}

function getCookie(name) {
    var re = new RegExp(name + "=([^;]+)");
    var value = re.exec(document.cookie);
    return (value != null) ? unescape(value[1]) : null;
}

function initMap() {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYXByaWxhZ2VudHVyIiwiYSI6ImNqcGJucWhhdTJwOWkzcXJ3OWozNDV2ZnUifQ.uKO5eU3n3f4UJS2XKxEcTQ';
    const map = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/aprilagentur/ck3u0hyvt054e1cs07fn5sxha',
        center: [13.6251752, 52.4493416],
        zoom: 15.0,
        scrollZoom: false,
        dragPan: false,
    });

    map.on('load', function () {

        map.addLayer({
          id: "points",
          type: "symbol",
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  geometry: {
                    type: "Point",
                    coordinates: [13.6250752, 52.4493416],
                  },
                  properties: {
                    title: "Physiotherapie Manuela Steinbach",
                  },
                },
              ],
            },
          },
          layout: {
            "text-field": "{title}",
            "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            "text-offset": [5.5, 1.5],
            "icon-size": 1,
            "text-anchor": "top",
          },
          paint: {
            "text-color": "#57616d",
          },
        });
    });

    var geojson = {
        type: 'physio',
        features: [{
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [13.6270752, 52.4493416],
            },
            properties: {
                title: 'physiotherapie',
                description: 'Scharnweberstraße 104<br>12587 Berlin',
                className: "physio-marker",
            },
        }],
    };

    geojson.features.forEach(function (marker) {

        // create a HTML element for each feature
        var el = document.createElement('div');
        el.className = marker.properties.className;

        // make a marker for each feature and add to the map
        new mapboxgl.Marker(el)
            .setLngLat(marker.geometry.coordinates)
            .addTo(map);
    });


    document.getElementById('mapInteraction').addEventListener('change', function (e) {
        if (e.target.checked) {
            map['dragPan'].enable();
            map['scrollZoom'].enable();
        } else {
            map['dragPan'].disable();
            map['scrollZoom'].disable();
        }
    });

}
