import Siema from 'siema'

if (document.getElementsByClassName('siema')[0]) {
    initSlider();
}

function initSlider() {

    const mySiema = new Siema({
        selector: '.siema',
        duration: 200,
        easing: 'ease-out',
        perPage: 1,
        startIndex: 0,
        draggable: true,
        multipleDrag: true,
        threshold: 20,
        loop: true,
        rtl: false,
        onInit: () => {
        },
        onChange: () => {
        },
    });

    const prev = document.querySelector('.siema-prev');
    const next = document.querySelector('.siema-next');

    prev.addEventListener('click', () => mySiema.prev());
    next.addEventListener('click', () => mySiema.next());
}


if (document.getElementsByClassName('about-slider')[0]) {
    initAboutSlider();
}

function initAboutSlider() {

    const aboutSiema = new Siema({
        selector: '.about-slider',
        duration: 200,
        easing: 'ease-out',
        perPage: 1,
        startIndex: 0,
        draggable: true,
        multipleDrag: true,
        threshold: 20,
        loop: false,
        rtl: false,
        onInit: () => {
        },
        onChange: () => {
            for (let i = 0; i < aboutSiema.innerElements.length; i++) {
                // if current dot matches currentSlide prop, add a class to it, remove otherwise
                const addOrRemove = aboutSiema.currentSlide === i ? 'add' : 'remove';
                document.querySelectorAll('.siema-dots')[i].classList[addOrRemove]('dots__item--active');
            }

            aboutPrev.classList.remove('disabled');
            aboutNext.classList.remove('disabled');
            if (aboutSiema.currentSlide === 0) {
                aboutPrev.classList.add('disabled')
            } else if (aboutSiema.currentSlide === aboutSiema.innerElements.length - 1) {
                aboutNext.classList.add('disabled')
            }
        },
    });

    const aboutPrev = document.querySelector('.about-siema-prev');
    const aboutNext = document.querySelector('.about-siema-next');

    aboutPrev.addEventListener('click', () => aboutSiema.prev());
    aboutNext.addEventListener('click', () => aboutSiema.next());


    const navDiv = document.createElement('div');
    navDiv.classList.add('nav');

    // check how many items we have inside a carousel
    // and create that many buttons and apend them to nav div
    // add a listened event to each of them to navigate to prticular slide
    for (let i = 0; i < aboutSiema.innerElements.length; i++) {
        const btn = document.createElement('button');
        // btn.textContent = i+1;
        btn.classList.add('siema-dots');
        if (i == 0) {
            btn.classList.add('dots__item--active');
        }
        btn.addEventListener('click', () => aboutSiema.goTo(i));
        navDiv.appendChild(btn);
    }

    // place the nav div after siema markup
    aboutSiema.selector.parentNode.insertBefore(navDiv, aboutSiema.selector.nextSibling);
}
